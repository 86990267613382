import React from "react";
import { useNavigate } from "react-router-dom";
import footerlogo from "./../assets/images/yoloh-logo-dark.webp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "../pages/Landing.css";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToTermsAndCondition = () => {
    navigate("/terms");
  };

  const navigateToStateAndLicense = () => {
    navigate("/state-and-license");
  };

  const navigateToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  return (
    <>
      {/* <section className="footer_section">
        <div className="container_width">
          <img src={footerlogo} alt="" width={100} />
          <div className="footer_details">
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <EmailOutlinedIcon />
              <a href="mailto:support@yoloh.com">connect@yoloh.com</a>
            </div>
            <p >Copyright @ 2023 - 2028</p>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontSize: "18px",
                flexWrap: "wrap",
              }}
            >
              <p onClick={navigateToStateAndLicense} className="footerTag" style={{cursor:"pointer"}}>
                State Licenses
              </p>
              <span>|</span>
              <p onClick={navigateToTermsAndCondition} className="footerTag" style={{cursor:"pointer"}}>
                Terms And Conditions
              </p>
              <span>|</span>
              <p onClick={navigateToPrivacyPolicy} className="footerTag" style={{cursor:"pointer"}}>
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="footer_section">
        <div className="container_width header_container">
          <div className="footer_details">
          
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <EmailOutlinedIcon />
              <p>connect@yoloh.com</p>
            </div>
            <div className="text-center">
               {/* <img src={footerlogo} alt="" width={100} /> */}
            
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontSize: "18px",
                flexWrap: "wrap",
                marginTop: "5px"
              }}
              className="footerhover"
            >
              <p onClick={navigateToStateAndLicense} className="footerTag" style={{cursor:"pointer"}}>
                State Licenses
              </p>
              <span>|</span>
              <p onClick={navigateToTermsAndCondition} className="footerTag" style={{cursor:"pointer"}}>
                Terms And Conditions
              </p>
              <span>|</span>
              <p onClick={navigateToPrivacyPolicy} className="footerTag" style={{cursor:"pointer"}}>
                Privacy Policy
              </p>
            </div>
            </div>
            <p >Copyright @ 2023 - 2028</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
