import React from "react";
import "./index.css";
import loaderJson from "../assets/lottie/quoteLoader.json";
import LottieLoader from "react-lottie-loader";

const Loader = () => {
  return (
    <div className="loader-contain">
      <LottieLoader animationData={loaderJson} />
    </div>
  );
};

export default Loader;
