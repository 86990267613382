import React from "react";
import { Navigate } from "react-router-dom";
import DrawerIndex from "../pages/DrawerIndex";
// import { Link } from "react-router-dom";

const PrivateRoute = ({ isLayout, children }) => {
  return localStorage.getItem("authUser") &&
    localStorage.getItem("authAwsCred") &&
    localStorage.getItem("authCognitoId") ? (
    <>{isLayout ? <DrawerIndex>{children}</DrawerIndex> : children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
