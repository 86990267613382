import { lazy } from "react";
import CodeConduct from "../pages/CodeConduct";
const landing = lazy(() => import("../pages/Landing"));
const dashboard = lazy(() => import("../pages/Dashboard"));
const agentEnrollment = lazy(() => import("../pages/AgentEnrollment/AgentEnrollment"));
const leadManagement = lazy(() => import("../pages/LeadManagement/LeadManagement"));
const leadManagementDetails = lazy(() => import("../pages/LeadManagementDetails/LeadManagementDetails"));
const warning = lazy(() => import("../components/Warning"));
const updateDetails = lazy(() => import("../pages/UpdateDetails/UpdateDetails"));
const terms = lazy(() => import("../pages/Terms"));
const privacy = lazy(() => import("../pages/Privacy"));
const product = lazy(() => import("../pages/Product"));
const chatComponent = lazy(() => import("../pages/LeadManagementDetails/Components/Agent"));
const yourPolicies = lazy(()=>import("../pages/YourPolicies/YourPolices"))
const statesAndPolicies = lazy(()=>import("../pages/StatesAndProducts/StatesAndProducts"))
const stateAndLicenseFooter = lazy(()=>import("../pages/Licenses​"))
const notification = lazy(()=>import("../pages/Notification/Notification"))


export const routes = [
  {
    element: terms,
    path: "/terms",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: stateAndLicenseFooter,
    path: "/state-and-license",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: privacy,
    path: "/privacy-policy",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: warning,
    path: "/warning/notapproved",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: warning,
    path: "/warning/rejected",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: updateDetails,
    path: "/update-details",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: dashboard,
    path: "/dashboard",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: leadManagement,
    path: "/lead-management",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: agentEnrollment,
    path: "/create-account",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: true,
  },
  {
    element: leadManagementDetails,
    path: "/lead-management-details/:sequenceId",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: landing,
    path: "/",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: product,
    path: "/product",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: chatComponent,
    path: "/chat/:sequenceId",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: yourPolicies,
    path: "/your-policies",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: notification,
    path: "/notification",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: statesAndPolicies,
    path: "/states-and-products",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
    isHeader: false
  },
  {
    element: CodeConduct,
    path: "/code-of-conduct",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  
];