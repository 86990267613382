import { Suspense, React, useContext, useEffect } from "react";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import Loader from "../components/Loader";
import LoaderContext from "../context/LoaderContext";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Lending from "../pages/Landing";
import { lazy } from "react";
const Header = lazy(() => import("../components/Header"));

const AppRoutes = () => {
  const { isLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("authUser") || "{}") &&
      !JSON.parse(localStorage.getItem("authCognitoId")|| "{}") &&
      !JSON.parse(localStorage.getItem("authAwsCred")|| "{}") &&
      !(
        location?.pathname === "/terms" ||
        location?.pathname === "/privacy-policy"
      )
    ) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
  }, []);

  return (
    <>
      {isLoader && <Loader loaderTransform="loaderTransform" />}
      <Suspense fallback={<Loader loaderTransform="loaderTransform" />}>
        <Routes>
          {routes?.map((route, index) => (
            <Route
              path={route?.path}
              key={"route_" + index}
              element={
                route.isPrivate ? (
                  <div className="main-container">
                    {route?.path !== "/create-account" && (
                      <Header
                        isLayout={route.isLayout}
                        isPrivate={route.isPrivate}
                        isView={route.isView}
                        isHeader={route?.isHeader}
                      />
                    )}
                    <PrivateRoute isLayout={route.isLayout}>
                      <route.element />
                    </PrivateRoute>
                  </div>
                ) : (
                  <>
                    <Header
                      isLayout={route.isLayout}
                      isPrivate={route.isPrivate}
                      isView={route.isView}
                      isHeader={route?.isHeader}
                    />
                    <route.element />
                    {route?.path !== "/warning/notapproved" && (
                      <Footer isPrivate={route.isPrivate} />
                    )}
                  </>
                )
              }
            />
          ))}
          <Route
            path="/"
            element={
              <>
                <Header />
                <Lending />
                <Footer />
              </>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
